import { combineReducers } from "redux";
import idToken from "./idToken";
import loginUser from "./loginUser";
import {events} from './events'
import {eventsForm} from './eventsForm'

export default combineReducers<RootReducer>({
  idToken,
  loginUser,
  events,
  eventsForm
});
