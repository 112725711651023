import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useDispatch } from "react-redux";
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Paper from '@material-ui/core/Paper';
import Styled from "styled-components";
import EventForm from './Form'
import {
  mapStateToFormProps,
  mapDispatchToFormProps
} from './mapToProps'
import { 
  submitUpdateEvents, 
  changeEventData,
  submitDeleteEvent
} from '@/store/actions/eventsForm'

const Event = ({
  startingPoint,
  attraction,
  experience,
  distanceFromLocation,
  businessHours,
  spotDetails,
  streetAddress,
  imageUrl,
  spotUrl
}: Firestore.Event) => (
  <TableContainer component={Paper}>
  <Table>
    <TableBody>
      <TableRow>
        <TableCell>起点</TableCell>
        <TableCell>{startingPoint}</TableCell>
      </TableRow>    
      <TableRow>
        <TableCell>観光名所</TableCell>
        <TableCell>{attraction}</TableCell>
      </TableRow>    
      <TableRow>
        <TableCell>体験</TableCell>
        <TableCell>{experience}</TableCell>
      </TableRow>    
      <TableRow>
        <TableCell>徒歩での移動時間（キョリ)</TableCell>
        <TableCell>{distanceFromLocation}</TableCell>
      </TableRow>    
      <TableRow>
        <TableCell>営業時間</TableCell>
        <TableCell>{businessHours}</TableCell>
      </TableRow>    
      <TableRow>
        <TableCell>スポット詳細</TableCell>
        <TableCell>{spotDetails}</TableCell>
      </TableRow>    
      <TableRow>
        <TableCell>住所</TableCell>
        <TableCell>{streetAddress}</TableCell>
      </TableRow>    
      <TableRow>
        <TableCell>画像</TableCell>
        <TableCell><img height="100px" src={imageUrl} alt={attraction} /></TableCell>
      </TableRow>    
      <TableRow>
        <TableCell>URL</TableCell>
        <TableCell>{spotUrl}</TableCell>
      </TableRow>
    </TableBody>
  </Table>        
  </TableContainer>        
)

const EditEventForm = connect(mapStateToFormProps, mapDispatchToFormProps(submitUpdateEvents))(EventForm)

export default (props: Firestore.Event) => {
  const dispatch = useDispatch()
  const [isHidden, setIsHidden] = useState<boolean>(true)
  const [formIsActive, setFormIsActive] = useState<boolean>(false);
  const handleEditButton = () => {
    dispatch(changeEventData(props))
    setIsHidden(true)
    setFormIsActive(true)
  }
  const handleDeleteButton = () => {
    const {id} = props
    dispatch(submitDeleteEvent(!!id ? id : ''))
  }

  return ( 
    <TableBody>
      <TableRow>
        <TableCell>{props.startingPoint}</TableCell>
        <TableCell>{props.attraction}</TableCell>
        <TableCell>{props.experience}</TableCell>
        <TableCell> 
          <Actions>
            <div onClick={() => setIsHidden(!isHidden)}>
              { isHidden ? <Open /> : <Close /> }
            </div>
            <div onClick={handleEditButton}>
              <Edit />
            </div>
            <div onClick={handleDeleteButton}>
              <Delete />
            </div>
          </Actions>
        </TableCell>
      </TableRow>
      { !isHidden && !formIsActive && <Event {...props} /> }    
      { formIsActive && <EditEventForm setFormIsActive={setFormIsActive}/> }    
    </TableBody>
  )
}

const Actions = Styled.div`
  display: flex;
  justify-content: space-between;
`

const Edit = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg" 
    height="24" 
    viewBox="0 0 24 24" 
    width="24"
  >
    <path 
      d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
    />
    <path 
      d="M0 0h24v24H0z" 
      fill="none"
    />
  </svg>
)

const Delete = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    height="24" viewBox="0 0 24 24" 
    width="24"
  >
      <path 
        d="M15 16h4v2h-4zm0-8h7v2h-7zm0 4h6v2h-6zM3 18c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2V8H3v10zM14 5h-3l-1-1H6L5 5H2v2h12z"
      />
      <path 
        d="M0 0h24v24H0z" 
        fill="none"
      />
   </svg>
)

const Open = () => (
<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"/></svg>
)
const Close = () => (
<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0z" fill="none"/><path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z"/></svg>
)
