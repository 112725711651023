import { postEvents, putEvent, deleteEvent } from '@/lib/request'
import { addImage } from '@/lib/firebase'
import { requestReload } from './events'

const eventsFormActions = {
  CHANGE_STARTING_POINT_VALUE: 'CHANGE_STARTING_POINT_VALUE',
  CHANGE_ATTRACTION_VALUE: 'CHANGE_ATTRACTION_VALUE',
  CHANGE_EXPERIENCE_VALUE: 'CHANGE_EXPERIENCE_VALUE',
  CHANGE_DISTANCE_FROM_LOCATION_VALUE: 'CHANGE_DISTANCE_FROM_LOCATION_VALUE',
  CHANGE_BUSINESS_HOURS_VALUE: 'CHANGE_BUSINESS_HOURS_VALUE',
  CHANGE_SPOT_DETAILS_VALUE: 'CHANGE_SPOT_DETAILS_VALUE',
  CHANGE_STREET_ADDRESS_VALUE: 'CHANGE_STREET_ADDRESS_VALUE',
  CHANGE_SPOT_URL_VALUE: 'CHANGE_SPOT_URL_VALUE',
  CHANGE_IMAGE_URL_VALUE: 'CHANGE_IMAGE_URL_VALUE',
  CHANGE_EVENT_DATA: 'CHANGE_EVENT_DATA',
  REQUEST_POST_EVENTS: 'REQUEST_POST_EVENTS',
  RECEIVED_ERROR: 'RECEIVED_POST_EVENTS_ERROR',
  RECEIVED_SUCCESS: 'RECEIVED_POST_EVENTS_SUCCESS',
  REQUEST_ADD_IMAGE: 'REQUEST_ADD_IMAGE',
  RECEIVED_ADD_IMAGE_ERROR: 'RECEIVED_ADD_IMAGE_ERROR'
}

const changeStartingPointValue = (value: string) => ({ 
  type: eventsFormActions.CHANGE_STARTING_POINT_VALUE,
  payload: {value}
})

const changeAttractionValue = (value: string) => ({
  type: eventsFormActions.CHANGE_ATTRACTION_VALUE,
  payload: {value}
}) 

const changeExperienceValue = (value: string) => ({
  type: eventsFormActions.CHANGE_EXPERIENCE_VALUE,
  payload: {value}
})

const changeDistanceFromLocationValue = (value: string) => ({
  type: eventsFormActions.CHANGE_DISTANCE_FROM_LOCATION_VALUE,
  payload: {value}
}) 

const changeBusinessHoursValue = (value: string) => ({
  type: eventsFormActions.CHANGE_BUSINESS_HOURS_VALUE,
  payload: {value}
})

const changeSpotDetailsValue = (value: string) => ({
  type: eventsFormActions.CHANGE_SPOT_DETAILS_VALUE,
  payload: {value}
})

const changeStreetAddressValue = (value: string) => ({
  type: eventsFormActions.CHANGE_STREET_ADDRESS_VALUE,
  payload: {value}
})

const changeSpotUrlValue = (value: string) => ({
  type: eventsFormActions.CHANGE_SPOT_URL_VALUE,
  payload: {value}
})

const changeImageUrlValue = (value: string) => ({
  type: eventsFormActions.CHANGE_IMAGE_URL_VALUE,
  payload: {value}
}) 

const changeEventData = (event: Firestore.Event) => ({
  type: eventsFormActions.CHANGE_EVENT_DATA,
  payload: { event }
})

const requestPostEvents = () => ({
  type: eventsFormActions.REQUEST_POST_EVENTS
})

const receivedError = (error: string) => ({
  type: eventsFormActions.RECEIVED_ERROR,
  payload: {error}
})

const receivedSuccess = (message: string) => ({
  type: eventsFormActions.RECEIVED_SUCCESS,
  payload: {message}
})

const requestAddImage = () => ({
  type: eventsFormActions.REQUEST_ADD_IMAGE
})

const receivedAddImageError = (error: string) => ({
  type: eventsFormActions.RECEIVED_ADD_IMAGE_ERROR,
  payload: {error}
})

const submitEvents = () => {
  return (dispatch: any, getState: any) => {
    const idToken = getState().idToken
    const event = getState().eventsForm.event

    dispatch(requestPostEvents())

    return postEvents(idToken, { events: [event] }).then(
      (response: any) => {
        dispatch(receivedSuccess('Event Successfully added'))
        dispatch(requestReload())
      },
      (error: any) => dispatch(receivedError(error.message))
    )
  }
}

const submitAddImage = (fs: FileList|null) => {
  return (dispatch: any, getState: any) => {
    if (!fs)
      return dispatch(receivedAddImageError('Unable to read images.'))

    const file = fs[0]
    const idToken = getState().idToken

    dispatch(requestAddImage())

    return addImage(idToken, file).then(
      (response: any) => dispatch(changeImageUrlValue(response)),
      (error: any) => dispatch(receivedAddImageError(error.message))
    )
  }
}

const submitUpdateEvents = () => {
  return (dispatch: any, getState: any) => {
    const idToken = getState().idToken
    const event = getState().eventsForm.event

    dispatch(requestPostEvents())

    return putEvent(idToken, event).then(
      (response: any) => {
        dispatch(receivedSuccess('Event successfully updated'))
        dispatch(requestReload())
      },
      (error: any) => dispatch(receivedError(error.message))
    )
  }
}

const submitDeleteEvent = (id: string) => {
  return (dispatch: any, getState: any) => {
    const idToken = getState().idToken

    return deleteEvent(idToken, id).then(
      (response: any) => {
        dispatch(receivedSuccess('Event successfully deleted'))
        dispatch(requestReload())
      },
      (error: any) => dispatch(receivedError(error.message))
    )
  }
}

export { 
  eventsFormActions,
  submitEvents,
  submitAddImage,
  submitUpdateEvents,
  submitDeleteEvent,
  changeStartingPointValue,
  changeAttractionValue,
  changeExperienceValue,
  changeDistanceFromLocationValue,
  changeBusinessHoursValue,
  changeSpotDetailsValue,
  changeStreetAddressValue,
  changeSpotUrlValue,
  changeEventData
}
