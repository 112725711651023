import React from 'react';
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Loading from '@/components/Loading';
import Event from './Event'
import {
  Message,
  Button,
} from './widgets'


type EventListProps = {
  loading: boolean,
  message: string,
  events: Firestore.Event[],
  onReloadEvents: () => void
}

export default ({loading, message, events, onReloadEvents}: EventListProps) => (
  <React.Fragment>

    <h3>イベントのリスト</h3>
    {message && <Message>{message}</Message>}
    { loading ? <Loading show={loading} /> 
      :  
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>起点</TableCell>
            <TableCell>観光名所</TableCell>
            <TableCell>体験</TableCell>
            <TableCell>
              <Button onClick={onReloadEvents}>Reload</Button>
            </TableCell>
          </TableRow>
        </TableHead>
          {events.map((event, i) => <Event key={i} {...event} />)}
      </Table>
    }
  </React.Fragment>
)
