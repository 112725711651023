import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { LayoutProps } from "./props";
import { login } from "@/lib/firebase";

export default (): LayoutProps => {
  const idToken = useSelector<RootReducer, string | null>(
    ({ idToken }) => idToken
  );

  const [emailAddress, onChangeEmailAddress] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [password, onChangePassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const result = await login(emailAddress, password);

    if (result.type === "Failure") {
      setErrorMessage(
        "ログインできませんでした。\nメールアドレスとパスワードをご確認ください。"
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    if (idToken) window.location.href = "/";
  }, [idToken]);

  return {
    emailAddress,
    password,
    loading,
    errorMessage,
    onChangeEmailAddress,
    onChangePassword,
    onSubmit
  };
};
