import React from "react";
import Styled from "styled-components";
import { color } from "@/values";

export default ({ fill }: { fill?: string | undefined }) => (
  <Svg
    id="logo"
    data-name="Logo"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 425.76 100.22"
    fill={fill}
  >
    <defs></defs>
    <title>LOGO</title>
  </Svg>
);

const Svg = Styled.svg<{ fill?: string | undefined }>`
  display: block;
  fill: ${({ fill }) => fill || color.main};
`;
