import {eventsActions} from '@/store/actions/events'

const initialState: Events = {
  needLoading: true,
  isLoading: false,
  message: '',
  events: []
}

const events = (state=initialState, action: any): Events => {
  switch (action.type) {
    case eventsActions.REQUEST_EVENTS:
      return {...state, needLoading: false, isLoading: true}
    case eventsActions.RECEIVED_ERROR:
      return {...state, needLoading: false, isLoading: false, message: action.payload.error}
    case eventsActions.RECEIVED_SUCCESS:
      return {...state, needLoading:false, isLoading: false, events: action.payload.events}
    case eventsActions.REQUEST_RELOAD:
      console.log('reload needed')
      return {...state, needLoading: true}
    default:
      return state
  }
}

export {events}
