import { eventsFormActions } from '@/store/actions/eventsForm'

const initialState: EventsForm = {
  message: '',
  isLoading: false,
  isLoadingImage: false,
  event: {
    startingPoint: '',
    attraction: '',
    experience: '',
    distanceFromLocation: '',
    businessHours: '',
    spotDetails: '',
    streetAddress: '',
    imageUrl: '',
    spotUrl: ''
  }
} 

const eventsForm = (state=initialState, action:any): EventsForm => {
  switch (action.type) {
    case eventsFormActions.CHANGE_STARTING_POINT_VALUE:
      return {...state, event: event(state.event, action)}
    case eventsFormActions.CHANGE_ATTRACTION_VALUE:
      return {...state, event: event(state.event, action)}
    case eventsFormActions.CHANGE_EXPERIENCE_VALUE:
      return {...state, event: event(state.event, action)}
    case eventsFormActions.CHANGE_DISTANCE_FROM_LOCATION_VALUE:
      return {...state, event: event(state.event, action)}
    case eventsFormActions.CHANGE_BUSINESS_HOURS_VALUE:
      return {...state, event: event(state.event, action)}
    case eventsFormActions.CHANGE_SPOT_DETAILS_VALUE:
      return {...state, event: event(state.event, action)}
    case eventsFormActions.CHANGE_STREET_ADDRESS_VALUE:
      return {...state, event: event(state.event, action)}
    case eventsFormActions.CHANGE_SPOT_URL_VALUE:
      return {...state, event: event(state.event, action)}
    case eventsFormActions.CHANGE_IMAGE_URL_VALUE:
      return {...state, isLoadingImage: false, event: event(state.event, action)}
    case eventsFormActions.CHANGE_EVENT_DATA:
      return {...state, event: action.payload.event}
    case eventsFormActions.REQUEST_POST_EVENTS:
      return {...state, isLoading: true }
    case eventsFormActions.RECEIVED_ERROR:
      return {...state, isLoading: false, message: action.payload.error}
    case eventsFormActions.RECEIVED_SUCCESS:
      return {...initialState, message: action.payload.message}
    case eventsFormActions.REQUEST_ADD_IMAGE:
      return {...state, isLoadingImage: true }
    case eventsFormActions.RECEIVED_ADD_IMAGE_ERROR:
      return {...state, isLoadingImage: false, message: action.payload.error}
    default:
      return state
  }
}

type EventAction = {
  type: string,
  payload: { value: string }
}

const event = (state: Firestore.Event, action: EventAction): Firestore.Event => {
  switch (action.type) {
    case eventsFormActions.CHANGE_STARTING_POINT_VALUE:
      return {...state, startingPoint: action.payload.value}
    case eventsFormActions.CHANGE_ATTRACTION_VALUE:
      return {...state, attraction: action.payload.value}
    case eventsFormActions.CHANGE_EXPERIENCE_VALUE:
      return {...state, experience: action.payload.value}
    case eventsFormActions.CHANGE_DISTANCE_FROM_LOCATION_VALUE:
      return {...state, distanceFromLocation: action.payload.value}
    case eventsFormActions.CHANGE_BUSINESS_HOURS_VALUE:
      return {...state, businessHours: action.payload.value}
    case eventsFormActions.CHANGE_SPOT_DETAILS_VALUE:
      return {...state, spotDetails: action.payload.value}
    case eventsFormActions.CHANGE_STREET_ADDRESS_VALUE:
      return {...state, streetAddress: action.payload.value}
    case eventsFormActions.CHANGE_SPOT_URL_VALUE:
      return {...state, spotUrl: action.payload.value}
    case eventsFormActions.CHANGE_IMAGE_URL_VALUE:
      return {...state, imageUrl: action.payload.value}
    default:
      return state
  }
}

export {eventsForm}
