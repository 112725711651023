import Styled from "styled-components";
import { RoundedBorderButton } from "@/components/Button";

const Message = Styled.div`
  text-align: center;
  color: #d1543f;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  white-space: pre-wrap;
`;

const Button = Styled(RoundedBorderButton)`
  margin: 0 0 2rem auto;
`;

const Row = Styled.div`
  display: flex;
  width: 80%;
  margin: 0.5rem;
`
const StartAt = Styled.div`
  flex: 1 1 40%;
`

const Attraction = Styled.div`
  flex: 1 1 40%;
`

const Action = Styled.div`
  flex: 1 1 20%;
`
export {
  Message,
  Button,
  Row,
  StartAt,
  Attraction,
  Action
}
