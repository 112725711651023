import {getEvents} from '@/lib/request'

const eventsActions = {
  REQUEST_EVENTS: 'REQUEST_EVENTS',
  RECEIVED_ERROR: 'RECEIVED_EVENTS_ERROR',
  RECEIVED_SUCCESS: 'RECEIVED_EVENTS_SUCCESS',
  REQUEST_RELOAD: 'REQUEST_EVENTS_RELOAD'
}

const requestEvents = () => ({
  type: eventsActions.REQUEST_EVENTS
})

const receivedError = (error: string) => ({
  type: eventsActions.RECEIVED_ERROR,
  payload: {error}
})

const receivedSuccess = (events: Firestore.Event[]) => ({
  type: eventsActions.RECEIVED_SUCCESS,
  payload: {events: (events ? events : [])}
})

const requestReload = () => ({
  type: eventsActions.REQUEST_RELOAD
})

const fetchEvents = () => {
  return (dispatch: any, getState: any) => {
    const idToken = getState().idToken

    dispatch(requestEvents())

    return getEvents(idToken).then(
      (response: any) => dispatch(receivedSuccess(response.events)),
      (error: any) => dispatch(receivedError(error.message))
    )
  }
}

export {eventsActions, requestReload, fetchEvents} 
