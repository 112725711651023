import React from "react";
import Styled from "styled-components";
import { Link } from "react-router-dom";
import { color } from "@/values";
import Logo from "@/components/Logo";
import { LayoutProps } from "./props";

export default ({ currentPath, logout, children }: LayoutProps) => (
  <Root>
    <Header>
      <HeaderTopLink to="/">
        <Logo fill="#fff" />
      </HeaderTopLink>

      <LogoutLink onClick={logout}>LOGOUT</LogoutLink>
    </Header>

    <Navigation>
      <NavigationItem active={currentPath.match(/^\/events$/) !== null}>
        <Link to="/events">イベント</Link>
      </NavigationItem>
    </Navigation>

    <Content>
      <ContentInner>{children}</ContentInner>
    </Content>
  </Root>
);

const Root = Styled.div`
  display: grid;
  grid-template-columns: 14rem 1fr;
  grid-template-rows: 80px 1fr;
  height: 100vh;
`;

const Header = Styled.div`
  background: ${color.main};
  color: #fff;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  grid-column: 1/3;
  grid-row: 1/2;
  z-index: 100;
`;

const HeaderTopLink = Styled(Link)`
  display: block;
  width: 12rem;
  margin: 0 auto 0 0;
  transition: opacity 0.25s ease;

  &:hover {
    opacity: 0.7;
  }
`;

const LogoutLink = Styled.div`
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: bolder;
  transition: opacity 0.25s ease;

  &:hover {
    opacity: 0.7;
  }
`;

const Navigation = Styled.ul`
  height: 100%;
  margin: 0;
  padding: 0;
  background: ${color.navigation};
  grid-column: 1/2;
  grid-row: 2/3;
  color: #fff;
  z-index: 10;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.35);
`;

const NavigationItem = Styled.li<{ active: boolean }>`
  display: block;
  list-style: none;
  border-bottom: 1px solid #212121;
  transition: background 0.25s ease, border 0.25s ease, color 0.25s ease;
  background: ${color.navigation};

  a {
    color: #ddd;
    display: block;
    padding: 1.4rem 1rem;
    font-size: 0.9rem;
    text-decoration: none;
    border-left: 5px solid rgba(0, 0, 0, 0);
  }

  ${({ active }) =>
    active &&
    `
    background: ${color.navigationHovered};
    border-left: 5px solid rgba(255, 255, 255, 1);

    a {
      color: #fff;
    }
  `}

  &:hover {
    background: ${color.navigationHovered};

    a {
      color: #fff;
    }
  }
`;

const Content = Styled.div`
  grid-column: 2/3;
  grid-row: 2/3;
  overflow: auto;
`;

const ContentInner = Styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  max-width: 840px;
  padding: 2rem 3rem;
  word-break: break-all;
`;
