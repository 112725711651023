const getEvents = (idToken: string) => getRequest<{ events: Firestore.Event[] }>('api/events', idToken)

const postEvents = (idToken: string, payload: { events: Firestore.Event[] }) => {
  return postRequest<{ events: Firestore.Event[] }>('api/events', idToken, payload)
}

const putEvent = (idToken: string, payload: Firestore.Event) => {
  return putRequest<{ event: Firestore.Event }>(`api/events/${payload.id}`, idToken, { event: payload })
}

const deleteEvent = (idToken:string, eventId: string) => {
  return deleteRequest<{}>(`api/events/${eventId}`, idToken)
}

const postRequest = async <T extends {}>(endPoint: string, idToken: string, payload: T) => {
  const response = await fetch(
    makeUrl(endPoint), 
    { 
      method: 'POST', 
      headers: makeHeaders(idToken),
      body: JSON.stringify(payload)
    }
  )
  if (!response.ok) {
    throw new Error(response.statusText)
  } 
  return ({})
}

const getRequest = async <T extends {}>(endPoint: string, idToken: string): Promise<T> => {
  const response = await fetch(
    makeUrl(endPoint),
    {
      method: 'GET',
      headers: makeHeaders(idToken)
    }
  )

  if (!response.ok) {
    throw new Error(response.statusText)
  } 
  return response.json() as Promise<T>
}

const putRequest = async <T extends {}>(endPoint: string, idToken: string, payload: T) => {
  const response = await fetch(
    makeUrl(endPoint), 
    { 
      method: 'PUT', 
      headers: makeHeaders(idToken),
      body: JSON.stringify(payload)
    }
  )
  if (!response.ok) {
    throw new Error(response.statusText)
  } 
  return response.json()
}

const deleteRequest = async <T extends {}>(endPoint: string, idToken: string) => {
  const response = await fetch(
    makeUrl(endPoint), 
    { 
      method: 'DELETE', 
      headers: makeHeaders(idToken),
    }
  )
  if (!response.ok) {
    throw new Error(response.statusText)
  } 
  return response.json()
}

const baseUrl = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net`;
const makeUrl = (endpoint: string) => {
  return `${baseUrl}/${endpoint}`;
};

const makeHeaders = (idToken: string) => ({
  "Content-Type": "application/json",
  Authorization: `Bearer ${idToken}`
});

export {getEvents, postEvents, putEvent, deleteEvent}
