import { requestReload } from '@/store/actions/events'
import { 
  submitAddImage,
  changeStartingPointValue,
  changeAttractionValue,
  changeExperienceValue,
  changeDistanceFromLocationValue,
  changeBusinessHoursValue,
  changeSpotDetailsValue,
  changeStreetAddressValue,
  changeSpotUrlValue
} from '@/store/actions/eventsForm'

// Form

type FormOwnProps = {
  setFormIsActive: (b: boolean) => void
}

const mapStateToFormProps = (state: RootReducer) => ({
  event: state.eventsForm.event,
  loading: state.eventsForm.isLoading
})

const mapDispatchToFormProps = (submitAction: Function) => (dispatch: any, ownProps: FormOwnProps) => {
  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    dispatch(submitAction())
    ownProps.setFormIsActive(false)
  }

  const onCancel = () => ownProps.setFormIsActive(false)

  return {
    onSubmit,
    onCancel,
    onChangeStartingPoint: (value: string) => dispatch(changeStartingPointValue(value)),
    onChangeAttraction: (value: string) => dispatch(changeAttractionValue(value)),
    onChangeExperience: (value: string) => dispatch(changeExperienceValue(value)),
    onChangeDistanceFromLocation: (value: string) => dispatch(changeDistanceFromLocationValue(value)),
    onChangeBusinessHours: (value: string) => dispatch(changeBusinessHoursValue(value)),
    onChangeStreetAddress: (value: string) => dispatch(changeStreetAddressValue(value)),
    onChangeSpotUrl: (value: string) => dispatch(changeSpotUrlValue(value)),
    onChangeImageUrl: (fs: FileList|null) => dispatch(submitAddImage(fs)),
    onChangeSpotDetails: (value: string) => dispatch(changeSpotDetailsValue(value))
  }
}

// List
const mapStateToListProps = (state: RootReducer) => ({
  loading: state.events.isLoading,
  message: state.events.message,
  events: state.events.events
})

const mapDispatchToListProps = (dispatch: any) => ({
  onReloadEvents: () => dispatch(requestReload())
})

export {
  mapStateToFormProps,
  mapDispatchToFormProps,
  mapStateToListProps,
  mapDispatchToListProps
}
