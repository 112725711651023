import { useRouteMatch } from "react-router";
import { logout } from "@/lib/firebase";
import { Props, LayoutProps } from "./props";

export default ({ title, children }: Props): LayoutProps => {
  document.title = `${title} | Tapick`;
  const { path: currentPath } = useRouteMatch();

  return {
    currentPath,
    logout,
    children
  };
};
