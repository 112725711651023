import React from "react";
import Styled from "styled-components";
import { WithAuth as Root } from "@/components/Root";
import PageTitle from "@/components/PageTitle";
import { RoundedBorderButton } from "@/components/Button";
import { LayoutProps } from "./props";
import {Form, List} from './components'

const title = 'イベント'

export default ({ 
  message, 
  formIsActive, 
  setFormIsActive,
  onCreateEvents
}: LayoutProps) => (
  <Root title={title}>
    <Header>
      <PageTitle>{title}</PageTitle>
    </Header>
    
    {message && <Message>{message}</Message>}
    { formIsActive ?
      <Form setFormIsActive={setFormIsActive} />  
      :   
      <Button onClick={onCreateEvents}>Add Event</Button>
    }

    <List />
 
  </Root>
);

const Header = Styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const Button = Styled(RoundedBorderButton)`
  margin: 0 0 2rem auto;
`;

const Message = Styled.div`
  text-align: center;
  color: #d1543f;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  white-space: pre-wrap;
`;
