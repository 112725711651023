import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Loading from '@/components/Loading';

type InputFunction = (v: string) => void

type EventFormProps = {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void,
  onCancel: () => void,
  onChangeStartingPoint: InputFunction,
  onChangeAttraction: InputFunction,
  onChangeExperience: InputFunction,
  onChangeDistanceFromLocation: InputFunction,
  onChangeBusinessHours: InputFunction,
  onChangeStreetAddress: InputFunction,
  onChangeSpotUrl: InputFunction,
  onChangeImageUrl: (fs: FileList|null) => void,
  onChangeSpotDetails: InputFunction,
  event: Firestore.Event,
  loading: boolean
}

export default ({
  onSubmit,
  onCancel,
  onChangeStartingPoint,
  onChangeAttraction,
  onChangeExperience,
  onChangeDistanceFromLocation,
  onChangeBusinessHours,
  onChangeStreetAddress,
  onChangeSpotUrl,
  onChangeImageUrl,
  onChangeSpotDetails,
  event,
  loading
}: EventFormProps) => (
  <div>
    { loading ? <Loading show={loading} /> 
      :  
      <form onSubmit={onSubmit}>
        <p>
          <label>起点</label><br />
          <TextField 
            id="standard-error-helper-text"
            type="text" 
            name="startingPoint"
            value={event.startingPoint}
            placeholder="どこを起点としています"
            onChange={(e) => onChangeStartingPoint(e.target.value) }
            variant="outlined"
            required
          />
        </p>
        <p>
          <label>観光名所</label><br />
          <TextField 
            type="text" 
            name="attraction"
            value={event.attraction}
            placeholder="観光名所"
            onChange={(e) => onChangeAttraction(e.target.value) }
            variant="outlined"
            required
          />
        </p>
        <p>
          <label>体験</label><br />
          <TextField 
            type="text" 
            name="experience"
            value={event.experience}
            placeholder="体験"
            onChange={(e) => onChangeExperience(e.target.value)}
            variant="outlined"
            required
          />
        </p>
        <p>
          <label>徒歩での移動時間（キョリ)</label><br />
          <TextField 
            type="text" 
            name="distanceFromLocation"
            value={event.distanceFromLocation}
            placeholder="徒歩での移動時間（キョリ)"
            onChange={(e) => onChangeDistanceFromLocation(e.target.value) }
            variant="outlined"
            required
          />
        </p>
        <p>
          <label>営業時間</label><br />
          <TextField 
            type="text" 
            name="businessHours"
            value={event.businessHours}
            placeholder="営業時間"
            onChange={(e) => onChangeBusinessHours(e.target.value) }
            variant="outlined"
            required
          />
        </p>
        <p>
          <label>住所</label><br />
          <TextField 
            type="text" 
            name="streetAddress"
            value={event.streetAddress}
            placeholder="住所"
            onChange={(e) => onChangeStreetAddress(e.target.value) }
            variant="outlined"
            required
          />
        </p>
        <p>
          <label>URL</label><br />
          <TextField 
            type="text" 
            name="spotUrl"
            value={event.spotUrl}
            placeholder="URL"
            onChange={(e) => onChangeSpotUrl(e.target.value) }
            variant="outlined"
            required
          />
        </p>
        <p>
          <label>Upload Image</label><br />
          <input 
            type="file" 
            name="imageUrl"
            onChange={(e) => onChangeImageUrl(e.target.files) }
          />
        </p>
        <p>
          <label>スポット詳細</label><br />
          <TextField
            multiline
            rows="4"
            name="spotDetails"
            value={event.spotDetails}
            placeholder="スポット詳細"
            onChange={(e) => onChangeSpotDetails(e.target.value) }
            variant="outlined"
            required
          />
        </p>
        <ButtonGroup size="small">
          <Button onClick={onCancel}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </ButtonGroup>
      </form>
    }
  </div>
)
