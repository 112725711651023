import { createRouter } from "./createRouter";
import TopPage from "@/pages/top";
import LoginPage from "@/pages/login";
import EventsPage from "@/pages/events";

export const Routes: CustomRoute[] = [
  {
    needAuth: false,
    path: "/login",
    component: LoginPage
  },
  {
    needAuth: true,
    path: "/events",
    component: EventsPage
  },
  {
    needAuth: true,
    path: "/",
    component: TopPage
  }
];

export const Router = createRouter(Routes);
