import { connect } from 'react-redux'
import EventList from './List'
import EventForm from './Form'
import {
  mapStateToFormProps,
  mapDispatchToFormProps,
  mapStateToListProps,
  mapDispatchToListProps
} from './mapToProps'
import { submitEvents } from '@/store/actions/eventsForm'

const Form = connect(mapStateToFormProps, mapDispatchToFormProps(submitEvents))(EventForm)

const List = connect(mapStateToListProps, mapDispatchToListProps)(EventList)

export {Form, List}
