import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LayoutProps } from "./props";
import { fetchEvents } from '@/store/actions/events'

export default (): LayoutProps => {
  const dispatch = useDispatch()
  const eventsNeedLoading = useSelector((state: RootReducer) => state.events.needLoading)
  const message = useSelector((state: RootReducer) => state.eventsForm.message)
  const [formIsActive, setFormIsActive] = useState<boolean>(false);
  const onCreateEvents = () => {
    setFormIsActive(!formIsActive)
  }

  if (eventsNeedLoading)
    dispatch(fetchEvents())

  return {
    message,
    formIsActive,
    setFormIsActive,
    onCreateEvents
  };
};
